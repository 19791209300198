import React from 'react';
import { Link } from 'react-router-dom';
import Image from './Image';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="col-span-1 md:col-span-2">
            <div className="h-12 mb-6">
              <Image name="logo" alt="BWell Company Logo" className="h-full w-auto brightness-0 invert" />
            </div>
            <p className="text-gray-400 max-w-md">
              BWell Company connects families with quality residential assisted living communities across the country.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/products" className="text-gray-400 hover:text-white">Products</Link></li>
              <li><Link to="/synccare360" className="text-gray-400 hover:text-white">SyncCare360</Link></li>
              <li><Link to="/training" className="text-gray-400 hover:text-white">Training</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/terms" className="text-gray-400 hover:text-white">Terms & Conditions</Link></li>
              <li><Link to="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</Link></li>
              <li><a href="mailto:hello@bwellcompany.com" className="text-gray-400 hover:text-white">Contact Us</a></li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} BWell Company. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}